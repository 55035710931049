<script setup>
import {computed, defineProps, ref, watch} from "vue";
import CountToggler from "@/components/base/CountToggler/CountToggler.vue";
import { useCartStore } from "@/store/CartStore";
import {formatPrice, getCurrencySymbol} from "@/helpers";
import {useMenuStore} from "@/store/MenuStore";
import { storeToRefs } from "pinia";

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const cartStore = useCartStore();
const menuStore = useMenuStore();
const { items } = storeToRefs(cartStore);

const currentProduct = computed(() =>
  items.value.find(item => item.id === props.product.id)
);

const handleCLickPlus = async () => {
  await cartStore.cartItemUpdate(currentProduct.value.id, currentProduct.value.quantity + 1);
};

const handleCLickMinus = async () => {
  if (currentProduct.value.quantity <= 1) {
    await cartStore.removeFromCart(currentProduct.value.id);
    return;
  }
  await cartStore.cartItemUpdate(currentProduct.value.id, currentProduct.value.quantity - 1);
};

const handleCLickCross = async () => {
  await cartStore.removeFromCart(currentProduct.value.id);
};

const selectedModifiers = ref([]);

const updatedModifiers = computed(() => {
  return currentProduct.value.product.modifiers.map((modifier) => {
    const existingSelection = selectedModifiers.value.find(
      (mod) => mod.modifier.id === modifier.id
    );
    return {
      ...modifier,
      selected: existingSelection ? existingSelection.selectedId : null,
    };
  });
});

const updateSelectedModifier = async (modifier, selectedId) => {
  const index = selectedModifiers.value.findIndex(mod => mod.modifier.id === modifier.id);

  if (index !== -1) {
    selectedModifiers.value[index] = { modifier, selectedId };
  } else {
    selectedModifiers.value.push({ modifier, selectedId });
  }

  const currentIngredients = currentProduct.value.ingredients.extra.map(item => ({
    id: item.ingredient.id,
    quantity: item.quantity
  }));

  await cartStore.cartItemUpdate(
    currentProduct.value.id,
    currentProduct.value.quantity,
    selectedModifiers.value.map(item => item.selectedId),
    currentIngredients
  );
};

// Обновляем модификаторы при изменении продукта в сторе
watch(currentProduct, (newProduct) => {
  if (newProduct) {
    selectedModifiers.value = newProduct.modifier_items.map((modifier) => {
      const existingSelection = newProduct.product.modifiers.find(
        (mod) => mod.items.some((item) => item.id === modifier.id)
      );
      return existingSelection ? {
        modifier: existingSelection,
        selectedId: modifier.id
      } : null;
    }).filter(Boolean);
  }
}, { immediate: true });

const handleCLickEditIngredients = () => {
  menuStore.openIngredientsModal(
    currentProduct.value.product,
    selectedModifiers.value,
    currentProduct.value.ingredients.extra
  );
};
</script>

<template>
  <v-sheet
    v-if="currentProduct"
    rounded="5"
    style="box-shadow: 0 0 11px rgba(0, 0, 0, 0.05)"
    class="w-100 pa-4 py-3 ga-4 rounded-xl position-relative"
  >
    <v-sheet class="d-flex flex-column align-center justify-center flex-sm-row ga-4">
      <v-sheet
        width="175"
        height="170"
        class="d-flex justify-center align-center"
      >
        <v-img
          width="175"
          height="auto"
          :src="currentProduct.product.image_full_url"
          cover
          rounded
        />
      </v-sheet>
      <v-sheet class="flex-1-0-0 h-100 d-flex flex-column">
        <v-card-title
          style="line-height: 1.2"
          class="text-subtitle-1 semibold pa-0 text-uppercase mb-4 text-pre-wrap pr-7"
        >
          {{ currentProduct.product.title }}
        </v-card-title>

        <v-sheet
          v-if="updatedModifiers.length > 0"
          class="w-100 d-flex flex-column pa-0 mb-3"
        >
          <v-sheet
            v-for="modifier in updatedModifiers"
            :key="modifier.id"
            class="custom-select"
          >
            <v-select
              v-if="modifier.items"
              v-model="modifier.selected"
              class="font-weight-light text-black pa-0"
              tile
              flat
              rounded="2"
              hide-details
              :items="modifier.items"
              density="compact"
              :placeholder="modifier.title_form"
              variant="plain"
              item-value="id"
              item-text="title"
              max-width="260"
              @update:model-value="(selectedId) => updateSelectedModifier(modifier, selectedId)"
            >
              <template #selection="{ item }">
                <v-sheet class="pa-0 font-weight-light text-caption">
                  {{ item.title }}
                </v-sheet>
              </template>
            </v-select>
          </v-sheet>
        </v-sheet>

        <v-sheet
          v-if="currentProduct.ingredients.extra.length > 0"
          class="pa-0 mb-2 pb-3"
        >
          <v-sheet
            v-for="ingredient in currentProduct.ingredients.extra"
            :key="ingredient.id"
            class="d-flex justify-space-between align-center flex-row"
          >
            <v-sheet class="text-grey-lighten-1 text-subtitle-2">
              {{ ingredient.ingredient.title }}
            </v-sheet>
            <v-sheet class="text-grey-lighten-1 text-subtitle-2 currency-symbol">
              {{ formatPrice(ingredient.total_price) }}&nbsp;{{ getCurrencySymbol(menuStore.currentCity) }}
            </v-sheet>
          </v-sheet>

          <v-sheet class="ma-0 pa-0">
            <v-btn
              variant="text"
              size="small"
              color="grey"
              class="pa-0 text-grey-lighten-1 text-subtitle-2"
              rounded="2"
              @click="handleCLickEditIngredients"
            >
              {{ $t('edit') }}
            </v-btn>
          </v-sheet>
        </v-sheet>

        <v-sheet>
          <count-toggler
            :handle-click-minus="handleCLickMinus"
            :handle-click-plus="handleCLickPlus"
            :value="currentProduct.quantity"
            :loading="true"
          />
        </v-sheet>
      </v-sheet>
    </v-sheet>
    <v-sheet
      class="d-flex position-absolute top-0 right-0 pt-4 pr-4"
    >
      <v-icon
        color="gray"
        class="text-grey-darken-1 cursor-pointer"
        icon="mdi-close"
        size="large"
        @click="handleCLickCross"
      />
    </v-sheet>
    <v-sheet
      class="d-flex flex-column position-absolute bottom-0 right-0 pb-4 pr-4 bg-transparent"
    >
      <v-sheet
        style="font-size: 15px;"
        class="semibold text-end"
      >
        {{ currentProduct.product.meta_weight }}г
      </v-sheet>
      <v-sheet
        style="font-size: 18px;"
        class="semibold font-weight-bold text-end currency-symbol"
      >
        {{ formatPrice(currentProduct.total_price) }}&nbsp;{{ getCurrencySymbol(menuStore.currentCity) }}
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<style scoped>
.custom-select >>> .v-field__input, .v-field-label {
  opacity: 1 !important;
  color: black;
  font-size: 12px;
}

.custom-select >>> .v-field__append-inner {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-select >>> .v-field__input {
  padding: 0;
}
</style>
