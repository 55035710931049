<script setup>
import {computed, ref, watch} from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import {useMenuStore} from "@/store/MenuStore";
import {storeToRefs} from "pinia";
import BaseInput from "@/components/base/BaseInput/BaseInput.vue";
import * as yup from "yup";
import {useField, useForm} from "vee-validate";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseSelect from "@/components/base/BaseSelect/BaseSelect.vue";
import {removeNonNumeric} from "@/helpers";
import router from "@/router";
import CyrillicToTranslit from "cyrillic-to-translit-js";

const authStore = useAuthStore();
const menuStore = useMenuStore();

const { isAuthModalOpen, postAuthAction } = storeToRefs(authStore)
const { cities, currentCity } = storeToRefs(menuStore);

const isLoading = ref(false);
const currentStep = ref(1);
const selectedCity = ref(null);
const isAgreeChecked = ref(false);
const phoneMask = ref('+{7}(000)000-00-00');
const isTashkent = ref(false);
const entities = ref([]);

watch(selectedCity, (newValue) => {
  phoneMask.value = newValue.title === 'Ташкент' ? '+{998}(00)000-00-00' : '+{7}(000)000-00-00';
  isTashkent.value = newValue.title === 'Ташкент';

  if (newValue.entities) {
    entities.value = newValue.entities;
  }
});

const schema = yup.object({
  phoneNumber: yup
      .string()
      .required('Введите номер телефона')
      .transform((value) => value.replace(/[^\d]/g, ''))
      .test(
          'is-valid-phone',
          'Введите корректный номер телефона',
          function (value) {
            return isTashkent.value
                ? /^\d{12}$/.test(value) // Для Ташкента (12 цифр)
                : /^\d{11}$/.test(value); // Для других городов (11 цифр)
          }
      ),
  name: yup.string().required('Введите имя').min(3, 'Имя не должно быть короче 3 символов'),
  verificationCode: yup
      .string()
      .when('currentStep', {
        is: 2, // Проверка кода только на втором шаге
        then: yup.string().required('Введите код верификации'),
      }),
});

const { errors } = useForm({
  validationSchema: schema,
});

// Определяем поля формы
const { value: phoneNumber } = useField('phoneNumber');
const { value: verificationCode } = useField('verificationCode');
const { value: name } = useField('name', {}, {validateOnValueUpdate: true});


// Шаг 1: Отправить запрос на получение кода верификации
const requestVerificationCode = async () => {
  isLoading.value = true;
  try {
    // Вызываем метод из Pinia store для отправки номера телефона
    await authStore.requestVerificationCode(phoneNumber.value);
    currentStep.value = 2; // Переходим на второй шаг (ввод кода)
  } catch (error) {
    console.error('Ошибка запроса кода верификации:', error);
  } finally {
    isLoading.value = false;
  }
};

// Шаг 2: Авторизация по коду верификации
const login = async () => {
  isLoading.value = true;
  try {
    await authStore.login(verificationCode.value, selectedCity.value.id, name.value);

    if (!currentCity.value) {
      await menuStore.setCurrentCity(selectedCity.value);
      return;
    }

    if (currentCity.value.id === selectedCity.value.id) {
      if (postAuthAction.value) {
        await postAuthAction.value();
        authStore.setPostAuthAction(null);
      }
    } else {
      const userCityRouteName = CyrillicToTranslit().transform(selectedCity.value.title).toLowerCase();
      await router.push({
        path: `/${userCityRouteName}`
      });
      await menuStore.setCurrentCity(selectedCity.value)
    }

    authStore.closeLoginModal();
  } catch (error) {
    console.error('Ошибка при авторизации:', error);
  } finally {
    isLoading.value = false;
  }
};

// Сброс формы и возврат на первый шаг
const resetForm = () => {
  phoneNumber.value = '';
  verificationCode.value = '';
  currentStep.value = 1;
};

const isButtonDisabled = computed(() => {
  const phoneLength = isTashkent.value ? 12 : 11;
  if (!phoneNumber.value) return true;
  if (removeNonNumeric(phoneNumber.value).length !== phoneLength) return true;
  if (!selectedCity.value) return true;
  if (!isAgreeChecked.value) return true;
  if (!name.value) return true;
  return false
});

const handleToggleChecked = () => {
  isAgreeChecked.value = !isAgreeChecked.value
}

</script>

<template>
  <v-dialog
    v-model="isAuthModalOpen"
    width="100%"
    max-width="500"
  >
    <v-sheet
      rounded="xl"
      class="pt-6 pb-12 px-8 d-flex justify-center align-center flex-column"
    >
      <v-sheet
        width="100%"
        max-width="260"
      >
        <v-img src="@/assets/Logo_Tom_Yam.svg" />
      </v-sheet>
      <v-card-text class="text-center text-customRed text-h4 font-weight-bold">
        {{ currentStep === 1 ? $t('login') : $t('enterVerificationCode') }}
      </v-card-text>

      <v-form
        v-if="currentStep === 1"
        ref="form"
        class="w-100"
      >
        <v-sheet
          class="d-flex justify-center align-center flex-column ga-4 px-4"
        >
          <base-input
            v-model="name"
            placeholder="Ваше имя"
            no-bottom-padding
            :error-messages="errors.name"
          />
          <base-input
            v-model="phoneNumber"
            placeholder="+7 (999) 123-45-67"
            no-bottom-padding
            :error-messages="errors.phoneNumber"
            :custom-mask="phoneMask"
          />
          <v-sheet
            class="w-100 pa-0 d-flex justify-center align-start flex-column"
          >
            <base-select
              v-model="selectedCity"
              :options="cities"
              :placeholder="$t('selectCity')"
              :is-loading="isLoading"
            />
          </v-sheet>
          <v-sheet
            v-if="entities && entities[0] && entities[0].privacy"
            class="w-100 pa-0"
          >
            <v-checkbox
              v-model="isAgreeChecked"
              class="pa-0 ma-0 mr-2"
              hide-details
              min-height="none"
              density="compact"
            >
              <template #input>
                <div
                  class="custom-checkbox pa-0"
                  :class="{
                    'custom-checkbox-checked': isAgreeChecked,
                    'custom-checkbox-disabled': false
                  }"
                  @click="handleToggleChecked"
                >
                  <v-icon
                    v-if="isAgreeChecked"
                    color="white"
                  >
                    mdi-check
                  </v-icon>
                </div>
              </template>
              <template #label>
                <v-card-text class="custom-checkbox-label semibold pa-0">
                  {{ $t('authAgreement') }}&nbsp;
                  <a
                    class="text-pre d-inline"
                    :href="entities[0].privacy || ''"
                    target="_blank"
                  >{{ $t('dataProcessingRules') }}</a>
                </v-card-text>
              </template>
            </v-checkbox>
          </v-sheet>
          <v-sheet
            max-width="220"
            class="pa-0 ma-0"
          >
            <base-button
              :disabled="isButtonDisabled"
              :loading="isLoading"
              :text="$t('getCode')"
              @click="requestVerificationCode"
            />
          </v-sheet>
        </v-sheet>
      </v-form>

      <v-form
        v-else
        ref="form"
      >
        <v-otp-input
          v-model="verificationCode"
          :length="4"
          variant="underlined"
          :error-messages="errors.verificationCode"
        />
        <v-sheet
          max-width="220"
          class="pa-0 ma-0 mx-auto"
        >
          <base-button
            :loading="isLoading"
            :text="$t('signIn')"
            @click="login"
          />
        </v-sheet>
      </v-form>
    </v-sheet>
  </v-dialog>
</template>

<style scoped>
.custom-checkbox {
  all: unset;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 2px solid #000;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s, border 0.3s;
}

.custom-checkbox-checked {
  background-color: #e31d24;
  border-color: #e31d24;
}

.custom-checkbox-disabled {
  background-color: #f5f5f5;
  border-color: #dcdcdc;
  cursor: not-allowed;
}

.custom-checkbox-label {
  margin-left: 8px;
  font-size: 14px;
  line-height: 1.2;
}
</style>
