<!--TODO: Надо привести тут код в порядок, очень кривой получился -->
<script setup>
import BaseTitle from "@/components/base/BaseTitle/BaseTitle.vue";
import { computed, ref, watch } from "vue";
import BaseInput from "@/components/base/BaseInput/BaseInput.vue";
import { useField, useForm } from "vee-validate";
import { VLabel, VTextField } from "vuetify/components";
import { format } from "date-fns";
import { useCartStore } from "@/store/CartStore";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/store/AuthStore";
import { combineDateTime, isDatesEqual } from "@/helpers";
import DadataAdressAutocomplete from "@/components/base/DadataAdressAutocomplete.vue";
import { useMenuStore } from "@/store/MenuStore";
import BaseButton from "@/components/base/BaseButton.vue";
import sendMetrik from "@/utils/sendMetrik";
import { appConfig } from "@/../config";
import axios from "axios";
const cartStore = useCartStore();
const authStore = useAuthStore();
const menuStore = useMenuStore();
const { currentCity } = storeToRefs(menuStore);
const { user } = storeToRefs(authStore);
const { selectedDeliveryType } = storeToRefs(cartStore);
const timeType = ref("now");
const menu = ref(false);
const formattedDate = ref("");
const name = useField(
  "name",
  {},
  { initialValue: user.value ? user.value.name : "" }
);
const phone = useField(
  "phone",
  {},
  { initialValue: user.value ? user.value.phone_number : "" }
);
const email = useField(
  "email",
  {},
  { initialValue: user.value ? user.value.email : "" }
);
const address = useField("address");
const fullDeliveryAddress = useField("fullAddress", {}, { initialValue: {} });
const apartment = useField("apartment");
const floor = useField("floor");
const domofon = useField("domofon");
const entrance = useField("entrance");
const comment = useField("comment");
const date = useField("date");
const time = useField("time");
const isCardTypeModalOpen = ref(false);
const selectedCardType = ref(null);
const selectedPayment = ref(0);
const isTashkent = computed(() => currentCity.value.title === "Ташкент");
const phoneMask = computed(() =>
  currentCity.value.title === "Ташкент"
    ? "+{998}(00)000-00-00"
    : "+{7}(000)000-00-00"
);
const showPaymentOptions = ref(true);
const paymentOptions = computed(() => {
  if (selectedDeliveryType.value === "PICKUP") {
    return (
      address.value?.value?.payments_types?.filter((p) => p.type !== 1) ?? []
    );
  }
  return address.value?.value?.payments_types ?? [];
});

const hasCoord = computed(() => {
  const data = fullDeliveryAddress.value?.value?.data;
  return Boolean(data?.latitude && data?.longitude);
});

const { handleSubmit, errors } = useForm({
  validationSchema: {
    name(value) {
      if (value?.length >= 2) return true;

      return "Имя должно содержать хотя бы 2 символа.";
    },
    phone(value) {
      if (/^[0-9-]{11,}$/.test(value)) return true;
      if (value) return true;
      return "Номер телефона должен быть 11 цифр.";
    },
    email(value) {
      if (!value) return "Email обязателен.";
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return true;
      return "Введите корректный email.";
    },
    address(value) {
      if (value) return true;

      return "Поле обязательно";
    },
    apartment(value) {
      if (value) return true;

      return "Поле обязательно";
    },
    comment() {},
  },
});
const policyUrl = "https://tomyumbar.ru/privacy.pdf";
const showAddressError = ref(false);
const showCalculateDeliveryError = ref(false);
// Функция для форматирования даты
const formatDate = () => {
  if (date.value) {
    formattedDate.value = format(new Date(date.value.value), "dd.MM.yyyy");
    menu.value = false; // Закрывает меню после выбора даты
  }
};
const isOrderCreated = ref(false);

watch(
  paymentOptions,
  (newPayments) => {
    if (newPayments.length === 1) {
      selectedPayment.value = newPayments[0].type;
    }
  },
  { immediate: true }
);

// Смотрим за изменениями в дате и форматируем ее
watch(date.value, formatDate);
watch(
  [() => address.value.value, selectedDeliveryType],
  async ([newAddress, newDeliveryType]) => {
    showPaymentOptions.value = newDeliveryType === "PICKUP";
    if (newDeliveryType === "PICKUP" && newAddress) {
      await cartStore.getWorkSchedulePickup(newAddress.id);
      await cartStore.GetCloseTimePoint(newAddress.id);
      await cartStore.calculateDelivery(
        null,
        time.value.value,
        newDeliveryType,
        false
      );
    } else if (newDeliveryType !== "PICKUP") {
      cartStore.clearDeliveryTime();
      timeOptions.value = [];
    }
    formattedDate.value = "";
    time.value.value = "";
  }
);

watch(apartment.value, async (newVal) => {
  if (apartment.value !== null && apartment.value.length !== 0) {
    if (!fullDeliveryAddress.value.value.data) return;
    const addressData = fullDeliveryAddress.value.value.data;

    const formAddress = {
      geo_lat: addressData.latitude,
      geo_lon: addressData.longitude,
    };
    showCalculateDeliveryError.value = newVal.length === 0;
    cartStore.toggleDeliveryPrice(newVal.length !== 0);
    if (hasCoord.value) {
      await cartStore.calculateDelivery(
        formAddress,
        time.value.value,
        "COURIER",
        false
      );
      await cartStore.getWorkScheduleDelivery(formAddress);
      await cartStore.GetCloseTimeAddress(formAddress);
    }
  }
});

watch(
  time.value,
  async () => {
    if (!time.value.value || selectedDeliveryType.value === "PICKUP") return;
    const addressData = fullDeliveryAddress.value.value.data;

    const formAddress = {
      geo_lat: addressData.latitude,
      geo_lon: addressData.longitude,
    };

    await cartStore.calculateDelivery(
      formAddress,
      time.value.value,
      "COURIER",
      false
    );
  },
  {
    deep: true,
  }
);

watch(date.value, () => {
  if (
    cartStore.schedulePickUpHours &&
    cartStore.schedulePickUpHours.length !== 0
  ) {
    const currentDateHours = cartStore.schedulePickUpHours.map((avDate) => {
      const isSameDates = isDatesEqual(
        formattedDate.value,
        "dd.MM.yyyy",
        avDate.date,
        "yyyy-MM-dd"
      );
      if (isSameDates) {
        timeOptions.value = avDate.hours;
      }
    });
  } else {
    return [];
  }
});

const isButtonDisabled = computed(() => {
  if (selectedDeliveryType.value === "PICKUP") {
    if (
      address.value.value === undefined ||
      name.value.value.length === 0 ||
      phone.value.value.length < 11
    )
      return true;

    if (timeType.value === "now") return false;

    if (time.value.value !== undefined) {
      return time.value.value.length === 0;
    }
    return true;
  } else {
    if (hasCoord.value) {
      return !(
        apartment.value.value !== undefined &&
        apartment.value.value.length !== 0
      );
    } else {
      return true;
    }
  }
});
const isDatePickerDisabled = computed(() => {
  if (pickupAllowedDates.value.length === 0) return true;
  if (selectedDeliveryType.value === "PICKUP") {
    return !(address.value.value !== undefined);
  } else {
    return !hasCoord.value;
  }
});

const pickupAllowedDates = computed(() => {
  return cartStore.schedulePickUpHours.map((item) => item.date);
});

const timeOptions = ref([]);

const onBlurAddressField = () => {
  showAddressError.value = !hasCoord.value;
};

const handleSelectItem = async (item) => {
  try {
    const response = await axios.post(
      `${appConfig.API_URL}/address/geocode`,
      {
        term: item.value,
      }
    );
    fullDeliveryAddress.value.value.data = {
      latitude: String(response.data.latitude),
      longitude: String(response.data.longitude),
      house: response.data.house,
      street: response.data.street,
      street_kladr_id: response.data.street_kladr_id,
      street_type: response.data.street_type,
      value: response.data.value,
      city: response.data.city,
      block: response.data.block,
    };
    const addressData = fullDeliveryAddress.value.value.data;
    const formAddress = {
      geo_lat: addressData.latitude,
      geo_lon: addressData.longitude,
    };
    await cartStore.getWorkScheduleDelivery(formAddress);
    apartment.value.value = "";
  } catch (error) {
    console.error("Ошибка при получении геоданных:", error);
  }

  showAddressError.value = !hasCoord.value;
};

const handleClickCreateOrder = () => {
  if (isTashkent.value) {
    isCardTypeModalOpen.value = true;
  } else {
    handleCreateOrder();
  }
};

const handleCreateOrder = async () => {
  try {
    const addressId = address.value?.value?.id
      ? address.value.value.id
      : undefined;
    isOrderCreated.value = true;
    const addressData = fullDeliveryAddress.value.value.data;
    const realDate = combineDateTime(
      time.value.value,
      date.value.value,
      user.value.city.timezone
    );
    const realComment = comment.value.value;
    const paymentType = selectedPayment.value
      ? parseInt(selectedPayment.value, 10)
      : 0;
    const formAddress = addressData
      ? {
          geo_lat: addressData.latitude,
          geo_lon: addressData.longitude,
          house: addressData.house,
          floor: floor.value.value,
          entrance: entrance.value.value,
          apartment: apartment.value.value,
          street: addressData.street,
          kladr_id: addressData.street_kladr_id,
          street_type: addressData.street_type,
          source: addressData.value,
          inner_city: addressData.city,
          corps: addressData.block,
        }
      : null;
    await cartStore.createOrder(
      formAddress,
      realDate,
      selectedDeliveryType.value,
      realComment,
      addressId,
      false,
      selectedCardType.value,
      paymentType
    );
  } catch (e) {
    console.error(e);
  } finally {
    isOrderCreated.value = false;
  }
};
</script>

<template>
  <v-sheet
    class="w-100 pa-0 d-flex justify-center align-center flex-column pa-3"
  >
    <base-title class="mb-4">
      {{ $t("placeOrder") }}
    </base-title>
    <v-sheet class="pa-0 w-100 mb-4">
      <v-tabs
        v-model="selectedDeliveryType"
        color="white"
        bg-color="rgba(0, 0, 0, 0.05)"
        class="rounded-xl w-100 d-flex"
      >
        <v-tab
          value="COURIER"
          rounded="xl"
          selected-class="bg-customRed"
          class="ma-0 w-100 flex-1-0-0"
          :text="$t('placeOrder')"
        />

        <v-tab
          value="PICKUP"
          rounded="xl"
          selected-class="bg-customRed"
          class="ma-0 w-100 flex-1-0-0"
          :text="$t('pickupFromRestaurant')"
        />
      </v-tabs>
    </v-sheet>
    <v-sheet class="w-100 pa-0">
      <base-input
        v-model="name.value.value"
        :error-messages="name.errorMessage.value"
        :label="$t('yourName') + `*`"
        :placeholder="$t('enterYourName')"
        @input="sendMetrik('name-input')"
      />

      <base-input
        v-model="phone.value.value"
        :error-messages="phone.errorMessage.value"
        :custom-mask="phoneMask"
        :label="$t('phoneNumber') + `*`"
        placeholder="+7 () ___ - __ - __"
        @input="sendMetrik('phone-input')"
      />
      <base-input
        v-if="currentCity.email_required"
        v-model="email.value.value"
        :error-messages="email.errorMessage.value"
        :label="$t('email') + `*`"
        :placeholder="$t('enterYourEmail')"
      />

      <v-sheet width="100%">
        <v-tabs-window v-model="selectedDeliveryType">
          <v-tabs-window-item value="COURIER">
            <v-sheet
              fluid
              class="pa-0"
            >
              <v-sheet
                class="w-100 pa-0 d-flex justify-center align-center flex-column"
              >
                <dadata-adress-autocomplete
                  v-model="fullDeliveryAddress.value.value"
                  :error-messages="fullDeliveryAddress.errorMessage.value"
                  placeholder="Адрес"
                  :label="$t('addressForm') + `*`"
                  :on-blur="onBlurAddressField"
                  :on-select="handleSelectItem"
                  @update:model-value="sendMetrik('address-input')"
                />
                <v-row class="w-100 pa-0 ga-4">
                  <v-col class="pa-0">
                    <base-input
                      v-model="apartment.value.value"
                      :error-messages="apartment.errorMessage.value"
                      :label="$t('apartment') + `*`"
                      placeholder="№"
                      @input="sendMetrik('address-input')"
                    />
                  </v-col>
                  <v-col class="pa-0">
                    <base-input
                      v-model="floor.value.value"
                      :error-messages="floor.errorMessage.value"
                      :label="$t('floor')"
                      @input="sendMetrik('address-input')"
                    />
                  </v-col>
                  <v-col class="pa-0">
                    <base-input
                      v-model="domofon.value.value"
                      :error-messages="domofon.errorMessage.value"
                      :label="$t('intercom')"
                      @input="sendMetrik('address-input')"
                    />
                  </v-col>
                  <v-col class="pa-0">
                    <base-input
                      v-model="entrance.value.value"
                      :error-messages="entrance.errorMessage.value"
                      :label="$t('entrance')"
                      @input="sendMetrik('address-input')"
                    />
                  </v-col>
                </v-row>
              </v-sheet>
              <v-sheet
                v-if="showCalculateDeliveryError"
                class="d-flex flex-row align-center ga-2 mb-4"
              >
                <v-sheet>
                  <v-img
                    class=""
                    width="5px"
                    src="@/assets/warn.png"
                  />
                </v-sheet>
                <v-sheet class="semibold">
                  {{ $t("pleaseFillApart") }}
                </v-sheet>
              </v-sheet>
              <v-sheet
                v-if="showAddressError"
                class="d-flex flex-row align-center ga-2 mb-4"
              >
                <v-sheet>
                  <v-img
                    class=""
                    width="5px"
                    src="@/assets/warn.png"
                  />
                </v-sheet>
                <v-sheet class="semibold">
                  {{ $t("incorrectAddress") }}
                </v-sheet>
              </v-sheet>
            </v-sheet>
          </v-tabs-window-item>
          <v-tabs-window-item value="PICKUP">
            <v-container
              fluid
              class="pa-0 w-100"
            >
              <v-sheet
                class="w-100 pa-0 d-flex justify-center align-start flex-column"
              >
                <v-label
                  class="text-black opacity-100 semibold mb-2 text-start"
                >
                  {{ $t("pickupFrom") }}
                </v-label>
                <v-select
                  v-model="address.value.value"
                  class="w-100"
                  flat
                  :error-messages="address.errorMessage.value"
                  variant="underlined"
                  density="compact"
                  return-object
                  :items="currentCity.addresses"
                />
              </v-sheet>
            </v-container>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-sheet>

      <v-sheet class="pa-0 w-100 mb-4">
        <v-tabs
          v-model="timeType"
          color="white"
          bg-color="rgba(0, 0, 0, 0.05)"
          class="rounded-xl"
        >
          <v-tab
            value="now"
            rounded="xl"
            selected-class="bg-customRed"
            :text="$t('nearestTime')"
            class="ma-0 w-100 flex-1-0-0"
          />

          <v-tab
            value="pickup"
            rounded="xl"
            selected-class="bg-customRed"
            class="ma-0 w-100 flex-1-0-0"
            :text="$t('chooseTime')"
          />
        </v-tabs>
      </v-sheet>

      <v-sheet width="100%">
        <v-tabs-window v-model="timeType">
          <v-tabs-window-item value="now">
            <v-container
              fluid
              class="pa-0"
            />
          </v-tabs-window-item>
          <v-tabs-window-item value="pickup">
            <v-container
              fluid
              class="pa-0 w-100"
            >
              <v-row>
                <v-col>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ props }">
                      <v-label class="text-black opacity-100 semibold mb-2">
                        {{ $t("chooseDate") }}
                      </v-label>
                      <v-text-field
                        v-model="formattedDate"
                        :error-messages="date.errorMessage.value"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="props"
                        flat
                        variant="underlined"
                        density="compact"
                        bg-color="#FFFFFF"
                      />
                    </template>
                    <v-date-picker
                      v-model="date.value.value"
                      hide-header
                      :disabled="isDatePickerDisabled"
                      :allowed-dates="pickupAllowedDates"
                      locale="ru-Ru"
                      :title="$t('chooseDate')"
                      @input="menu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  :class="
                    isDatePickerDisabled ? 'd-flex align-items-center' : ''
                  "
                >
                  <v-label
                    class="text-black opacity-100 semibold mb-2 text-start"
                  >
                    {{
                      !isDatePickerDisabled
                        ? $t("deliveryTime")
                        : $t("enterAddress")
                    }}
                  </v-label>
                  <v-select
                    v-if="!isDatePickerDisabled"
                    v-model="time.value.value"
                    class="w-100"
                    flat
                    :error-messages="time.errorMessage.value"
                    variant="underlined"
                    density="compact"
                    :items="timeOptions"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-sheet>
      <template v-if="showPaymentOptions && paymentOptions.length > 1">
        <v-label class="text-black opacity-100 semibold mb-4 block">
          {{ $t("choosePaymentType") }}
        </v-label>

        <v-radio-group
          v-model="selectedPayment"
          row
        >
          <v-radio
            v-for="payment in paymentOptions"
            :key="payment.type"
            :label="payment.name"
            :value="payment.type"
            class="payment-option"
          />
        </v-radio-group>
      </template>
      <input
        v-else
        v-model="selectedPayment"
        type="hidden"
        name="payment"
      >

      <v-label class="text-black opacity-100 semibold mb-0">
        {{ $t("orderComment") }}
      </v-label>
      <v-textarea
        v-model="comment.value.value"
        :error-messages="comment.errorMessage.value"
        variant="underlined"
        class="pa-0"
        row-height="15"
        rows="2"
        density="compact"
        @input="sendMetrik('comment-input')"
      />

      <v-checkbox>
        <template #label>
          <v-label class="text-black opacity-100 semibold">
            {{ $t("noCallConfirmation") }}
          </v-label>
        </template>
      </v-checkbox>

      <v-sheet class="w-100 pa-0 text-subtitle-2 semibold custom-line-height">
        {{ $t("personalDataAgreement") }}
        <a
          class="text-decoration-underline cursor-pointer text-customRed semibold d-inline"
          :href="policyUrl"
          target="_blank"
        >
          {{ $t("privacyPolicyCart") }}
        </a>
      </v-sheet>

      <v-btn
        class="rounded-xl mt-4 py-5"
        height="auto"
        color="customRed"
        width="100%"
        :disabled="isButtonDisabled"
        :loading="isOrderCreated"
        @click="handleClickCreateOrder"
      >
        {{ $t("proceedToPayment") }}
      </v-btn>
    </v-sheet>
  </v-sheet>

  <v-dialog
    transition="dialog-bottom-transition"
    width="500"
    :model-value="isCardTypeModalOpen"
    @update:model-value="(value) => (isCardTypeModalOpen = value)"
  >
    <v-sheet class="d-flex flex-column align-center py-8 px-6 ga-4 rounded-xl">
      <v-sheet
        width="100%"
        max-width="260"
      >
        <v-img src="@/assets/Logo_Tom_Yam.svg" />
      </v-sheet>

      <v-sheet class="text-h6 text-center">
        Выберите какой картой будете оплачивать заказ
      </v-sheet>

      <v-btn-toggle
        v-model="selectedCardType"
        mandatory
        variant="outlined"
      >
        <v-btn
          color="customRed"
          value="INTERNATIONAL"
        >
          Международная
        </v-btn>

        <v-btn
          color="customRed"
          value="FOREIGN"
        >
          Национальная
        </v-btn>
      </v-btn-toggle>

      <v-sheet>
        <base-button
          :disabled="!selectedCardType"
          text="Оплатить"
          :loading="isOrderCreated"
          @click="
            () => {
              handleCreateOrder();
              sendMetrik('payment-click');
            }
          "
        />
      </v-sheet>
    </v-sheet>
  </v-dialog>
</template>
