<script setup>
import CurrentOrderInfoLine from "@/components/Profile/CurrentOrder/CurrentOrderInfoLine.vue";
import {useProfileStore} from "@/store/ProfileStore";
import {storeToRefs} from "pinia";
import {computed, onBeforeMount} from "vue";
import ProfileCustomButton from "@/components/Profile/components/ProfileCustomButton.vue";
import router from "@/router";
import {formatDate, getCurrencySymbol} from "@/helpers";
import {useMenuStore} from "@/store/MenuStore";

const profileStore = useProfileStore()
const menuStore = useMenuStore();
const { currentCity } = storeToRefs(menuStore);
const {orders} = storeToRefs(profileStore);

onBeforeMount(() => {
  if (profileStore) {
    profileStore.getOrders(true, 1);
  }
})

const currentOrder = computed(() => {
  return orders.value && orders.value.data.length ? orders.value.data[0] : null;
});

const handleNavigateToMenu = () => {
  router.push({
    path: "/",
  })
}

</script>

<template>
  <v-sheet class="pa-0 ma-o default-profile-title">
    {{ $t('currentOrder') }}
  </v-sheet>
  <v-sheet class="pa-0 ma-0 w-100 pt-4">
    <v-row class="w-100">
      <v-col
        cols="12"
        md="8"
      >
        <v-container
          v-if="profileStore.isLoading"
          class="fill-height pa-0"
          fluid
        >
          <v-skeleton-loader
            color="gray"
            type="image"
            class="w-100 h-100 fullscreen-skeleton"
          />
        </v-container>
        <v-sheet
          v-else
          class="w-100"
        >
          <v-sheet
            v-if="currentOrder"
            class="pa-1"
            elevation="2"
            rounded="lg"
          >
            <v-sheet
              class="pa-5 d-flex flex-column align-start ga-3"
            >
              <current-order-info-line>
                <template #icon>
                  <v-icon
                    icon="mdi-clock-time-eight"
                    color="customRed"
                  />
                </template>
                <template #title>
                  {{ $t('orderDate') }}
                </template>
                <template #value>
                  {{ formatDate(currentOrder.completion_time) || '---' }}
                </template>
              </current-order-info-line>
              <current-order-info-line>
                <template #icon>
                  <v-icon
                    icon="mdi-map-marker"
                    color="customRed"
                  />
                </template>
                <template #title>
                  {{ $t('deliveryAddress') }}
                </template>
                <template #value>
                  {{
                    currentOrder.address_delivery || '---'
                  }}
                </template>
              </current-order-info-line>
              <current-order-info-line>
                <template #icon>
                  <v-icon
                    icon="mdi-comment-text"
                    color="customRed"
                  />
                </template>
                <template #title>
                  {{ $t('orderComments') }}
                </template>
                <template #value>
                  {{ currentOrder.comment || '---' }}
                </template>
              </current-order-info-line>
            </v-sheet>

            <v-divider />

            <v-sheet
              class="pa-3 d-flex flex-column align-start ga-3 position-relative pb-0"
            >
              <v-sheet class="pl-14 text-grey-darken-1">
                {{ $t('order') }}
              </v-sheet>
              <v-sheet
                class="w-100 d-flex flex-column ga-3 overflow-y-scroll pb-10"
                max-height="260px"
              >
                <v-sheet
                  v-for="item in currentOrder.items"
                  :key="item.id"
                  class="w-100 d-flex flex-column"
                >
                  <v-sheet class="w-100 d-flex justify-space-between ga-4">
                    <v-sheet
                      class="text-customRed semibold text-center"
                      min-width="42px"
                    >
                      {{ item.quantity }} {{ $t('pcs') }}.
                    </v-sheet>
                    <v-sheet class="flex-1-0-0 semibold">
                      {{ item.product.title }}
                    </v-sheet>
                  </v-sheet>

                  <v-sheet
                    v-for="modifier in item.modifier_items "
                    :key="modifier.id"
                    class="pl-2 pt-1 text-subtitle-2"
                  >
                    <span class="semibold">{{ modifier.modifier_title }}:</span> {{ modifier.title }}
                  </v-sheet>

                  <v-sheet v-if="item.ingredients.extra.length > 0">
                    <v-sheet class="pa-0 ma-0 pl-2">
                      <span
                        style="font-size: 10px"
                        class="text-grey-lighten-1"
                      >{{ $t('extra') }}:</span>
                    </v-sheet>

                    <v-sheet
                      v-for="ingredient in item.ingredients.extra "
                      :key="ingredient.id"
                      class="pl-2 text-subtitle-2"
                    >
                      <span class="text-grey-darken-1">{{ ingredient.title }} x {{ ingredient.quantity }}</span>
                    </v-sheet>
                  </v-sheet>
                </v-sheet>
              </v-sheet>

              <v-sheet
                class="position-absolute bottom-0 left-0 w-100"
                height="40px"
                style="background: linear-gradient(0deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 100%); pointer-events: none;"
              />
            </v-sheet>

            <v-divider />

            <v-sheet class="d-flex flex-row align-end justify-space-between ga-3 pa-4">
              <v-sheet class="semibold text-h6">
                {{ $t('total') }}
              </v-sheet>
              <v-sheet
                class="bg-customRed mb-2 flex-1-0-0"
                height="2px"
              />
              <v-sheet class="bold text-customRed text-h5">
                {{ currentOrder.total_price.toLocaleString('ru-RU').replace(/,/g, ' ') }}&nbsp;{{ getCurrencySymbol(currentCity) }}
              </v-sheet>
            </v-sheet>
          </v-sheet>
          <v-sheet
            v-else
            min-height="100%"
            class="d-flex flex-column align-center justify-center ga-5"
          >
            <v-sheet class="text-h3 semibold d-inline-flex align-center ga-2">
              {{ $t('noOrders') }}
              <v-sheet class="d-inline">
                <v-img
                  width="46px"
                  height="36px"
                  src="@/assets/eyes.png"
                />
              </v-sheet>
            </v-sheet>
            <v-sheet class="text-center text-h6 text-grey-darken-1">
              {{ $t('noOrdersText') }}
            </v-sheet>
            <v-sheet
              class="w-100"
              max-width="400px"
            >
              <profile-custom-button
                :on-click="handleNavigateToMenu"
                is-active
                size="small"
              >
                {{ $t('toMenu') }}
              </profile-custom-button>
            </v-sheet>
          </v-sheet>
        </v-sheet>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-img
          src="@/assets/banners/profile_banner_right.png"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<style scoped>

</style>
