import { defineStore } from "pinia";
import apolloClient from "../../apolloClient";
import {
  CART,
  CART_ITEM_CREATE,
  WORK_SCHEDULE_DELIVERY,
  WORK_SCHEDULE_PICKUP,
  CLOSE_TIME_ADDRESS,
  CLOSE_TIME_POINT
} from "@/api/queries";
import {CART_CALCULATE_DELIVERY, CART_CLEAR, CART_ITEM_DELETE, CART_ITEM_UPDATE, ORDER_CREATE} from "@/api/mutations";
import {computed, ref} from "vue";
import router from "@/router";
import {useAlertStore} from "@/store/AlertStore";
import {useMenuStore} from "@/store/MenuStore";
import { storeToRefs } from "pinia";
import { DateTime } from "luxon";
import {useI18n} from "vue-i18n";

export const useCartStore = defineStore('cart', () => {
  const {currentCity} = storeToRefs(useMenuStore())
  const { t } = useI18n();
  const items = ref([]);
  const selectedDeliveryType = ref('courier');
  const total_price = ref(0);
  const delivery_price = ref(0);
  const isLoading = ref(false);
  const schedulePickUpHours = ref([]);
  const closeTimePoint = ref([]);
  const order = ref(null);
  const bagsInfo = ref({ quantity: 0, price: 0 });
  const orderProcessingInfo = ref({ price: 0 });
  const error = ref('');
  const isShowDeliveryPrice = ref(false);
  const alertStore = useAlertStore();
  // Геттеры (getters)
  const cartTotal = computed(() => items.value.reduce((total, item) => total + item.quantity, 0));
  const cartItems = computed(() => items.value);
  const fullCartPrice = computed(() => items.value.reduce((total, item) => {
    return total + item.total_price
  }, 0));
  const pickupAllowedDates = computed(() => schedulePickUpHours.value.map(item => item.date));

  const itemsToShow = computed(() => {
    return items.value.filter(
      (item) => {
        const excludedTitles = [
          t('extraProductsFields.bag'),
          t('extraProductsFields.chopsticks'),
          t('extraProductsFields.spoons'),
          t('extraProductsFields.forks'),
          t('extraProductsFields.disposableSet'),
          t('extraProductsFields.orderProcessing'),
          t('extraProductsFields.delivery')
        ].map(title => title.toLowerCase());

        return !excludedTitles.includes(item.product.title.toLowerCase());
      }
    );
  });

  const updateBagsInfo = (bagsCartItem) => {
    if (bagsCartItem !== undefined) {
      bagsInfo.value = {
        price: bagsCartItem.total_price,
        quantity: bagsCartItem.quantity,
      };
    } else {
      bagsInfo.value = {
        price: 0,
        quantity: 0,
      };
    }
  }
  const getCart = async (locale) => {
    isLoading.value = true;
    try {
      const response = await apolloClient.query({
        query: CART,
        variables: {
          locale
        }
      });

      const allItems = response.data.cart.items;
      const bagsItem = allItems.find(item => item.product.title === t('extraProductsFields.bag'));
      updateBagsInfo(bagsItem);
      const orderProcessingItem = allItems.find(item => item.product.title === t('extraProductsFields.orderProcessing'));

      if (orderProcessingItem !== undefined) {
        orderProcessingInfo.value = {
          price: orderProcessingItem.total_price,
        };
      }

      items.value = response.data.cart.items;
      delivery_price.value = response.data.cart.delivery_price;
      total_price.value = response.data.cart.total_price;
    } catch (err) {
      // alertStore.openAlert('error', 'Ошибка загрузки корзины')
    } finally {
      isLoading.value = false;
    }
  };

  const addToCart = async (product, modifiers = [], ingredients = [], quantity = null) => {
    let existingItem;
    try {
      if (items.value.length !== 0) {
        existingItem = items.value.find(item => item.id === product.id);
      }

      if (existingItem) {
        existingItem.quantity++;
      } else {
        const response = await apolloClient.mutate({
          mutation: CART_ITEM_CREATE,
          variables: {
            product_id: product.id,
            quantity: quantity ? quantity : product.quantity,
            modifier_items: modifiers,
            extra_ingredients: ingredients,
            part_remove_ingredients: [],
          },
        });

        items.value = response.data.cartItemCreate.items;

        const bagsItem = response.data.cartItemCreate.items.find(item => item.product.title === t('extraProductsFields.bag'));
        updateBagsInfo(bagsItem);

        delivery_price.value = response.data.cartItemCreate.delivery_price;
        total_price.value = response.data.cartItemCreate.total_price;
      }
    } catch (e) {
      return e;
    }
  };

  const cartItemUpdate = async (cartItemId, quantity, modifier_items = undefined, ingredients = undefined) => {
    try {
      const response = await apolloClient.mutate({
        mutation: CART_ITEM_UPDATE,
        variables: {
          id: cartItemId,
          quantity: quantity,
          modifier_items: modifier_items ? modifier_items : undefined,
          extra_ingredients: ingredients ? ingredients : undefined,
        },
      });

      items.value = response.data.cartItemUpdate.items;

      const bagsItem = response.data.cartItemUpdate.items.find(item => item.product.title === t('extraProductsFields.bag'));
      updateBagsInfo(bagsItem);

      delivery_price.value = response.data.cartItemUpdate.delivery_price;
      total_price.value = response.data.cartItemUpdate.total_price;
    } catch (e) {
    // alertStore.openAlert('error', 'Ошибка')
    }
  };

  const removeFromCart = async (cartItemId) => {
    try {
      const response = await apolloClient.mutate({
        mutation: CART_ITEM_DELETE,
        variables: {
          id: cartItemId,
        },
      });

      items.value = response.data.cartItemDelete.items;

      const bagsItem = response.data.cartItemDelete.items.find(item => item.product.title === t('extraProductsFields.bag'));
      updateBagsInfo(bagsItem);

      delivery_price.value = response.data.cartItemDelete.delivery_price;
      total_price.value = response.data.cartItemDelete.total_price;
    } catch (e) {
      // alertStore.openAlert('error', 'Ошибка')
    }
  };

  const getWorkSchedulePickup = async (cityId) => {
    try {
      const response = await apolloClient.query({
        query: WORK_SCHEDULE_PICKUP,
        variables: {
          city_address_id: cityId,
        },
      });
      schedulePickUpHours.value = response.data.workSchedulePickup;
    } catch (err) {
      // alertStore.openAlert('error', 'Ошибка загрузки расписания')
    }
  };


  const GetCloseTimePoint = async (cityId) => {
    try {
      const response = await apolloClient.query({
        query: CLOSE_TIME_POINT,
        variables: {
          address: cityId,
        },
      });

      const timezone = currentCity.value.timezone;
      let endOrderTimeUTC = response.data.closeTimePoint.end_order;

      const nowInCityTimeZone = DateTime.now().setZone(timezone);
      const endOrderTimeInCityTimeZone = DateTime.fromISO(endOrderTimeUTC, { zone: timezone });

      const timeDifferenceInMinutes = Math.floor(
        endOrderTimeInCityTimeZone.diff(nowInCityTimeZone, "minutes").minutes
      );


      if (timeDifferenceInMinutes > 0 && timeDifferenceInMinutes <= 15) {
        await alertStore.openAlert(
          "warning",
          `Приём заказов из Вашего любимого ресторана закроется через ${timeDifferenceInMinutes} минут(ы). Большая просьба оплатить свой заказ до ${endOrderTimeInCityTimeZone.toFormat("HH:mm")}.`
        );
      }
    } catch (err) {
      console.error("Ошибка выполнения GetCloseTimePoint:", err);
    }
  };

  const GetCloseTimeAddress = async (formAddress) => {
    try {
      const response = await apolloClient.query({
        query: CLOSE_TIME_ADDRESS,
        variables: {
          address: formAddress,
        },
      });
      const timezone = currentCity.value.timezone;
      let endOrderTimeUTC = response.data.closeTimeAddress.end_order;

      const nowInCityTimeZone = DateTime.now().setZone(timezone);
      const endOrderTimeInCityTimeZone = DateTime.fromISO(endOrderTimeUTC, { zone: timezone });

      const timeDifferenceInMinutes = Math.floor(
        endOrderTimeInCityTimeZone.diff(nowInCityTimeZone, "minutes").minutes
      );

      console.log("Текущее время в таймзоне:", nowInCityTimeZone.toString());
      console.log("Время закрытия в UTC:", endOrderTimeUTC.toString());
      console.log("Осталось минут до закрытия:", timeDifferenceInMinutes);

      if (timeDifferenceInMinutes > 0 && timeDifferenceInMinutes <= 15) {
        await alertStore.openAlert(
          "warning",
          `Приём заказов из Вашего любимого ресторана закроется через ${timeDifferenceInMinutes} минут(ы). Большая просьба оплатить свой заказ до ${endOrderTimeInCityTimeZone.toFormat("HH:mm")}.`
        );
      }
    } catch (err) {
      console.error("Ошибка выполнения GetCloseTimePoint:", err);
    }
  };

  const getWorkScheduleDelivery = async (formAddress) => {
    try {
      const response = await apolloClient.query({
        query: WORK_SCHEDULE_DELIVERY,
        variables: {
          address: formAddress,
        },
      });
      schedulePickUpHours.value = response.data.workScheduleDelivery;
    } catch (err) {
      // alertStore.openAlert('error', 'Ошибка загрузки расписания')
    }
  };

  const clearCart = async () => {
    try {
      const response = await apolloClient.mutate({
        mutation: CART_CLEAR,
      });
      items.value = response.data.clearCart.items;
      const bagsItem = response.data.clearCart.items.find(item => item.product.title === t('extraProductsFields.bag'));
      updateBagsInfo(bagsItem);
      delivery_price.value = response.data.clearCart.delivery_price;
      total_price.value = response.data.clearCart.total_price;
    } catch (err) {
      // alertStore.openAlert('error', 'Ошибка загрузки расписания')
    }
  };

  const calculateDelivery = async (formAddress, time, type, payOnDelivery) => {
    const deliveryData = {
      address: type === 'PICKUP' ? undefined : {
        ...formAddress,
        save: false,
      },
      time,
      type,
      pay_on_delivery: payOnDelivery,
    };

    try {
      const response = await apolloClient.mutate({
        mutation: CART_CALCULATE_DELIVERY,
        variables: {
          delivery: deliveryData,
        },
      });

      total_price.value = response.data.cartCalculateDelivery.total_price;
      delivery_price.value = response.data.cartCalculateDelivery.delivery_price;
    } catch (err) {
      // alertStore.openAlert('error', 'Ошибка расчета доставки')
    }
  };

  const createOrder = async (formAddress, time, type, comment, pickupPointId, payOnDelivery = false, card_type, paymentType) => {
    console.log('paymentType paymentType', paymentType)
    try {
      const deliveryData = {
        address: type === 'COURIER' ? {
          ...formAddress,
          save: false,
        } : undefined,
        time,
        type,
        pay_on_delivery: payOnDelivery,
      };

      const pickup = {
        city_address_id: pickupPointId,
        notify_completed: true,
      };

      const response = await apolloClient.mutate({
        mutation: ORDER_CREATE,
        variables: {
          delivery: deliveryData,
          pickup: type === 'PICKUP' ? pickup : undefined,
          payment: {
            type: paymentType,
          },
          comment: comment || '',
          card_type: card_type ? {
            type: card_type,
          } : undefined,
        },
      });
      order.value = response.data.orderCreate;
      window.location.href = order.value.payment_url;
    } catch (err) {
      // alertStore.openAlert('error', 'Ошибка создания заказа')
    }
  };

  const clearDeliveryTime = () => {
    schedulePickUpHours.value = [];
  }

  const addDeviceToCart =  async (product, quantity) => {
    try {
      const response = await apolloClient.mutate({
        mutation: CART_ITEM_CREATE,
        variables: {
          product_id: product.id,
          quantity: quantity,
          modifier_items: [],
          extra_ingredients: [],
          part_remove_ingredients: [],
        },
      });

      items.value = response.data.cartItemCreate.items;

      const bagsItem = response.data.cartItemCreate.items.find(item => item.product.title === 'Пакет');
      updateBagsInfo(bagsItem);
      delivery_price.value = response.data.cartItemCreate.delivery_price;
      total_price.value = response.data.cartItemCreate.total_price;
    } catch (e) {
      console.error('error', e);
    }

  };

  const toggleDeliveryPrice = (value) => {
    isShowDeliveryPrice.value = value;
  }

  const addSeveralItemsToCart = async (items) => {
    try {
      await Promise.all(items.map(async (item) => {
        const extraIngredients = item.ingredients.extra.map(item => {
          return {
            id: item.id,
            quantity: item.quantity,
          }
        })
        return await addToCart(item.product, item.modifier_items, extraIngredients, item.quantity);
      }));
      await router.push({
        path: "/cart",
      })
    } catch (error) {
      console.error(error);
    }
  }

  // Возвращаем все состояния, геттеры и действия
  return {
    items,
    selectedDeliveryType,
    total_price,
    delivery_price,
    isLoading,
    schedulePickUpHours,
    order,
    bagsInfo,
    orderProcessingInfo,
    error,

    // Геттеры
    cartTotal,
    cartItems,
    fullCartPrice,
    pickupAllowedDates,
    isShowDeliveryPrice,
    itemsToShow,

    // Действия
    getCart,
    addToCart,
    cartItemUpdate,
    removeFromCart,
    getWorkSchedulePickup,
    GetCloseTimePoint,
    GetCloseTimeAddress,
    getWorkScheduleDelivery,
    calculateDelivery,
    createOrder,
    clearDeliveryTime,
    addDeviceToCart,
    toggleDeliveryPrice,
    clearCart,
    addSeveralItemsToCart
  };
});

